<template>
    <div class="box_guidance">
        <div
            v-for="item in detailData"
            :key="item.drugEncode">
            <!-- 商品名（通用名） -->
            <div class="product">
                <div class="title van-hairline--bottom">
                    <div class="name">
                        {{ item.drugName +'('+item.commonName+')' }}
                    </div>
                </div>
                <div
                    v-if="item.introduction"
                    v-html="item.introduction"
                    class="product_content common_content" />
                <div
                    v-else
                    class="product_content common_content">
                    暂无简介
                </div>
            </div>
            <!-- 药品贮存 -->
            <div
                v-if="item.storageCondition"
                class="product">
                <div class="title van-hairline--bottom">
                    <div class="name">
                        药品贮存
                    </div>
                </div>
                <div
                    v-html="item.storageCondition"
                    class="drug_store common_content" />
            </div>
            <!-- 用法用量 -->
            <div
                v-if="item.usage "
                class="product">
                <div class="title van-hairline--bottom">
                    <div class="name">
                        用法用量
                    </div>
                </div>
                <div
                    v-html="item.usage"
                    class="usage_content common_content" />
            </div>
            <!-- 用法须知 -->
            <div
                v-if="item.notice"
                class="product">
                <div class="title van-hairline--bottom">
                    <div class="name">
                        用药须知
                    </div>
                </div>
                <div
                    v-html="item.notice"
                    class="medication_content common_content" />
            </div>
        </div>
        <div
            v-if="detailData.length"
            class="link_img">
            <img
                :src="require('@img/link_img.png')"
                @click="goDoctor"
                class="go_img">
        </div>
    </div>
</template>
<script >
import api from '@/api';
import WxTool from '@/utils/wx-tool';
import buryingPoint from '@/mixins/buryingPoint';

export default {
    mixins: [buryingPoint],
    data() {
        return {
            detailData: [],
        };
    },
    created() {
        this.getInit();
        this.getShare();
    },

    methods: {
        getInit() {
            api.getGuidanceByDetail({ mainOrderNo: this.$route.params.guidanceNo })
                .then((res) => {
                    if (res.code === '0') {
                        this.detailData = res.data;
                    }
                });
        },
        getShare() {
            // 分享参数更新
            const instance = WxTool.getInstance();
            const shareParam = {
                shareId: 'share001',

            };
            // if (res.data.productImageUrl) {
            //     const [img] = res.data.productImageUrl;
            //     shareParam.imgUrl = img;
            // }
            setTimeout(() => {
                instance.onShare(shareParam);
            }, 1000);
        },

        goDoctor() {
            window.location.href = 'https://m.spcare.cn/advisory-doctor?channelEncode=bd29ab8968f11acd&loginChannel=bd29ab8968f11acd';
        },
    },
};
</script>

<style lang="scss" scoped>
    .box_guidance {
        background: #f5f7fa;
        // height: calc(100vh - 151px);
        height: 100vh;
        overflow: scroll;
        .product {
            background: #fff;
            margin-bottom: 9px;
        }
        .title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            // height: 50px;
            line-height: 30px;
            padding: 10px 18px;
            .name {
                flex: 1;
                font-size: 16px;
                font-weight: 500;
                color: #333;
                // white-space: nowrap;
                // text-overflow: ellipsis;
                // overflow: hidden;
                // word-break: break-all;
            }
        }
        .common_content {
            font-size: 14px;
            font-weight: 400;
            color: #62636c;
            line-height: 22px;
            white-space: pre-wrap;
        }
        .product_content {
            padding: 17px 17px 17px 18px;
        }
        .drug_store {
            padding: 17px 17px 21px 18px;
        }
        .usage_content {
            padding: 17px 17px 19px 18px;
        }
        .medication_content {
            padding: 17px 17px 17px 18px;
        }
        .link_img {
            width: 375px;
            height: 120px;
            position: relative;
            bottom: 17px;
            left: 0;
            right: 0;
            cursor: pointer;
            margin-top: 31px;
            .go_img {
                width: 100%;
                height: 100%;
            }
        }
    }

</style>
